
export const BACKGROUND_COLOR = '#00564C';

export const ACCENT_COLOR = '#BBBBBB';
export const ACCENT_COLOR_DARK = '#003630';

export const PRIMARY_BUTTON_TEXT_COLOR = BACKGROUND_COLOR;

export const PRIMARY_TEXT_COLOR = '#B2BCCC';
export const SECONDARY_TEXT_COLOR = '#698AB9'; //new

export const TEXT_COLOR_ERROR = '#AC3522';