export const TEXT_SIZE_VERY_SMALL = '0.8em';
export const TEXT_SIZE_SMALL = '0.9em';
export const TEXT_SIZE_NORMAL = '1em';
export const TEXT_SIZE_SLIGHTLY_BIGGER = '1.15em';
export const TEXT_SIZE_BIG = '1.3em';
export const TEXT_SIZE_BIGGER = '2em';
export const TEXT_SIZE_VERY_BIG = '2.5em';
export const TEXT_SIZE_VERY_VERY_BIG = '3em';

export const TEXT_SIZE_BUTTON_SMALL = '0.8em';
export const TEXT_SIZE_BUTTON_NORMAL = '0.9em';
export const TEXT_SIZE_BUTTON_BIG = '0.95em';

export const SMALL_INPUT_MAX_WIDTH_CONTENT = 400;