import { css, StyleSheet } from 'aphrodite';
import React, { useState } from 'react';
import {
    MuiThemeProvider,
    Button,
    TextField,
} from '@material-ui/core';

import { authenticate } from '../authentication';
import { ACCENT_COLOR, TEXT_COLOR_ERROR } from '../styling/colors';
import { SMALL_INPUT_MAX_WIDTH_CONTENT, TEXT_SIZE_VERY_BIG } from '../styling/dimens';
import { DEFAULT_PADDING, defaultStyles, materialUiTheme } from '../styling/styles';
import { delay } from '../util/DelayUtil';

export type LoginProps = {
    setAuthenticatedStatus: (authenticatedStatus: boolean) => void;
};

export default function Login(loginProps: LoginProps) {
  const [username, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorInfo, setErrorInfo] = useState<string>('');
  async function onClickLoginButton() {
    const authenticated = await loginUser(
        username,
        password
    );

    if (!authenticated) {
        setErrorInfo("Fehler beim Login");
        await delay(2500);
        setErrorInfo('');
      }

    loginProps.setAuthenticatedStatus(authenticated);
  }

  return(
    <div className={css(styles.container)}>
      <span className={css(styles.header)}>
        <div>Login</div>
      </span>

      <div className={css(styles.spacing)} />
      <div className={css(styles.spacing)} />
      <MuiThemeProvider theme={materialUiTheme}>
        <TextField
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              onClickLoginButton();
            }
          }}
          type="text"
          onChange={(input) => {
            setUserName(input.target.value);
          }}
          fullWidth
          label="Nutzername"
        />
        <div className={css(styles.spacing)} />
        <TextField
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
                onClickLoginButton();
            }
          }}
          type="password"
          onChange={(input) => {
            setPassword(input.target.value);
          }}
          fullWidth
          label="Passwort"
        />
        <div className={css(styles.spacing)} />

        <Button
          className={css(styles.primaryButton)}
          variant="contained"
          onClick={() => onClickLoginButton()}
          color="primary"
        >
          Login
        </Button>
      </MuiThemeProvider>
      <div className={css(styles.spacing)} />
      <span className={css(styles.errorText)}>{errorInfo}</span>
    </div>
  )
}

async function loginUser(username: string, password: string): Promise<boolean> {
    return new Promise((resolve) => 
        authenticate(username, password, (authenticated: boolean) => {
            resolve(authenticated)
        })
    );
}

const styles = StyleSheet.create({
    container: {
      ...defaultStyles.containerStyle,
      padding: DEFAULT_PADDING,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: SMALL_INPUT_MAX_WIDTH_CONTENT,
    },
    primaryButton: {
      ...defaultStyles.materialPrimaryButtonStyle,
      fontWeight: 'bold',
      width: '-webkit-fill-available',
    },
    header: {
      ...defaultStyles.textStyle,
      fontSize: TEXT_SIZE_VERY_BIG,
      fontWeight: 'bold',
      color: ACCENT_COLOR,
      wordBreak: 'break-word',
    },
    spacing: {
      height: 35,
    },
    errorText: {
      ...defaultStyles.textStyle,
      textAlign: 'justify',
      color: TEXT_COLOR_ERROR,
    },
  });
  