import { css, StyleSheet } from 'aphrodite';
import { useEffect, useState } from 'react';
import { fetchBackend } from '../authentication';
import { ACCENT_COLOR_DARK, BACKGROUND_COLOR, PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from '../styling/colors';
import { defaultStyles } from '../styling/styles';
import { ReactComponent as DeleteIcon } from '../icons/delete.svg';
import { ReactComponent as BackIcon } from '../icons/back.svg';
import { Alarm, Room } from '../server/model/RoomsWithAlarm';
import { Button, FormControlLabel, makeStyles, Slider, Switch, TextField } from '@material-ui/core';
import { STORED_SELECTED_ROOM } from '../App';
import { useHistory, useParams } from 'react-router-dom';
import { TEXT_SIZE_BIG, TEXT_SIZE_NORMAL, TEXT_SIZE_SLIGHTLY_BIGGER } from '../styling/dimens';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

type EditAlarmParams = {
    id: string;
  };

export default function AddAlarmComponent() {
    const [loadedGivenAlarm, setlLoadedGivenAlarm] = useState<boolean>(
        false
    );

    const [selectedRoom, setSelectedRoom] = useState<Room | undefined>(
        undefined
    );

    const [hours, setHours] = useState<number>(
        6
    );
    const [minutes, setMinutes] = useState<number>(
        0
    );
    const [name, setName] = useState<string>(
        ''
    );
    const [timerOnlyOnce, setTimerOnlyOnce] = useState<boolean>(
        true
    );
    const [timerOnMonday, setTimerOnMonday] = useState<boolean>(
        false
    );
    const [timerOnTuesday, setTimerOnTuesday] = useState<boolean>(
        false
    );
    const [timerOnWednesday, setTimerOnWednesday] = useState<boolean>(
        false
    );
    const [timerOnThursday, setTimerOnThursday] = useState<boolean>(
        false
    );
    const [timerOnFriday, setTimerOnFriday] = useState<boolean>(
        false
    );
    const [timerOnSaturday, setTimerOnSaturday] = useState<boolean>(
        false
    );
    const [timerOnSunday, setTimerOnSunday] = useState<boolean>(
        false
    );
    const [lightEnabled, setLightEnabled] = useState<boolean>(
        false
    );
    const [lightIntensity, setLightIntensity] = useState<number>(
        20
    );
    const [lightDuration, setLightDuration] = useState<number>(
        60
    );
    const [blindsEnabled, setBlindsEnabled] = useState<boolean>(
        false
    );
    const [blindsOpeness, setBlindsOpeness] = useState<number>(
        20
    );
    const [blindsDuration, setBlindsDuration] = useState<number>(
        60
    );
    const [buttonAddAlarmDisabled, setButtonAddAlarmDisabled] = useState<boolean>(
        false
    );

    const { id } = useParams<EditAlarmParams>();
    const history = useHistory();
    const materialStyles = materilUiStyles();

    useEffect(() => {
        async function getSelectedRoom() {
            const storedSelectedRoom = localStorage.getItem(STORED_SELECTED_ROOM);
            setSelectedRoom(storedSelectedRoom as Room)
        };
        if (selectedRoom === undefined) getSelectedRoom();
    }, [selectedRoom]);

    useEffect(() => {
        async function getAlarm() {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                  id: Number(id)
                }),
            };
            const response = await fetchBackend(
                'naturalAlarm/getAlarm',
                requestOptions
            );
            
            if (response.success) {
                const receivedAlarm = response.alarm as Alarm;
                setName(receivedAlarm.name);
                setTimerOnlyOnce(receivedAlarm.isSingleUsedTimer);
                setTimerOnMonday(receivedAlarm.enabledDays?.indexOf('MONDAY') >= 0);
                setTimerOnTuesday(receivedAlarm.enabledDays?.indexOf('TUESDAY') >= 0);
                setTimerOnWednesday(receivedAlarm.enabledDays?.indexOf('WEDNESDAY') >= 0);
                setTimerOnThursday(receivedAlarm.enabledDays?.indexOf('THURSDAY') >= 0);
                setTimerOnFriday(receivedAlarm.enabledDays?.indexOf('FRIDAY') >= 0);
                setTimerOnSaturday(receivedAlarm.enabledDays?.indexOf('SATURDAY') >= 0);
                setTimerOnSunday(receivedAlarm.enabledDays?.indexOf('SUNDAY') >= 0);
                setLightEnabled(receivedAlarm.enabledLightDimmer);
                setLightIntensity(receivedAlarm.lightDimmerEndPositionInPercent);
                setLightDuration(receivedAlarm.lightDimmerEndPositionDurationInSeconds);
                setBlindsEnabled(receivedAlarm.enabledWindowShade);
                setBlindsOpeness(receivedAlarm.windowShadeEndPositionInPercent);
                setBlindsDuration(receivedAlarm.windowShadeEndPositionDurationInSeconds);
                setHours(receivedAlarm.hours);
                setMinutes(receivedAlarm.minutes);
            }

            setlLoadedGivenAlarm(true);
        };
        if (id && !loadedGivenAlarm) getAlarm();
    }, [id, loadedGivenAlarm]);

    var captionChosenOnlyOnce = "Nur Heute";
    const date = new Date();
    const currentTimeHours = date.getHours();
    const currentTimeMinutes = date.getMinutes();

    if (currentTimeHours > hours || (currentTimeHours === hours && currentTimeMinutes >= minutes)) {
        captionChosenOnlyOnce = "Nur Morgen";
    }

    if (!timerOnlyOnce && !timerOnMonday && !timerOnTuesday && !timerOnWednesday && !timerOnThursday && !timerOnFriday && !timerOnSaturday && !timerOnSunday) {
        setTimerOnlyOnce(true);
    }

    const styleForDaySelectionOnlyOnce = timerOnlyOnce ? styles.daySelectionTextActive : styles.daySelectionTextInactive;
    const styleForDaySelectionMonday = timerOnMonday
        ? styles.daySelectionTextActive
        : styles.daySelectionTextInactive;

    const styleForDaySelectionTuesday = timerOnTuesday
        ? styles.daySelectionTextActive
        : styles.daySelectionTextInactive;

    const styleForDaySelectionWednesday = timerOnWednesday
        ? styles.daySelectionTextActive
        : styles.daySelectionTextInactive;

    const styleForDaySelectionThursday = timerOnThursday
        ? styles.daySelectionTextActive
        : styles.daySelectionTextInactive;

    const styleForDaySelectionFriday = timerOnFriday
        ? styles.daySelectionTextActive
        : styles.daySelectionTextInactive;

    const styleForDaySelectionSaturday = timerOnSaturday
        ? styles.daySelectionTextActive
        : styles.daySelectionTextInactive;

    const styleForDaySelectionSunday = timerOnSunday
        ? styles.daySelectionTextActive
        : styles.daySelectionTextInactive;

  async function onClickSaveAlarm() {
    const path = id ? 'naturalAlarm/editAlarm' : 'naturalAlarm/addAlarm'
    setButtonAddAlarmDisabled(true);

    const days = [];
    if (timerOnMonday) days.push('MONDAY');
    if (timerOnTuesday) days.push('TUESDAY');
    if (timerOnWednesday) days.push('WEDNESDAY');
    if (timerOnThursday) days.push('THURSDAY');
    if (timerOnFriday) days.push('FRIDAY');
    if (timerOnSaturday) days.push('SATURDAY');
    if (timerOnSunday) days.push('SUNDAY');

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ 
        id: id ? Number(id) : undefined,
        name: name,
        minutes: minutes,
        hours: hours,
        targetNaturalAlarmRoom: selectedRoom,
        isSingleUsedTimer: timerOnlyOnce,
        enabledDays: days,
        enabledWindowShade: blindsEnabled,
        windowShadeEndPositionInPercent: blindsOpeness,
        windowShadeEndPositionDurationInSeconds: blindsDuration,
        enabledLightDimmer: lightEnabled,
        lightDimmerEndPositionInPercent: lightIntensity,
        lightDimmerEndPositionDurationInSeconds: lightDuration
     }),
    };
    await fetchBackend(
        path,
        requestOptions
    );

    setButtonAddAlarmDisabled(false);
    history.replace('/list');
  }

  async function onClickRemoveAlarm() {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ id: Number(id) }),
    };
    await fetchBackend(
      'naturalAlarm/removeAlarm',
      requestOptions
    );
    history.replace('/list');
  }

  function sliderLightTransform(value: number) {
    return `${value}%`;
  }

  function sliderLightDurationTransform(value: number) {
    if (value < 60) return `${value}s`;

    return `${value/60}min`;
  }

  function sliderBlindsTransform(value: number) {
    return `${value}%`;
  }

  function sliderBlindsDurationTransform(value: number) {
    if (value < 60) return `${value}s`;

    return `${value/60}min`;
  }

  const sliderLightMarks = [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 100,
      label: '100%',
    }
  ];

  const sliderLightDurationMarks = [
    {
      value: 30,
      label: '30s',
    },
    {
      value: 600,
      label: '10min',
    }
  ];

  const sliderBlindsMarks = [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 100,
      label: '100%',
    }
  ];

  const sliderBlindsDurationMarks = [
    {
      value: 30,
      label: '30s',
    },
    {
      value: 600,
      label: '10min',
    }
  ];

  if (!selectedRoom) return null

  if (id && !loadedGivenAlarm) {
    return (
        <div className={css(styles.loadingContainer)}>
            <Box sx={{ display: 'flex' }}>
                <CircularProgress className={css(styles.loadingSpinner)}/>
            </Box>
        </div>
    )
  }

  const title = id ? "Wecker bearbeiten" : "Neuer Wecker";
  const showDeleteIcon = id !== undefined;
  const hoursForTimestamp = hours < 10 ? '0' + hours : hours.toString();
  const minutesForTimestamp = minutes < 10 ? '0' + minutes : minutes.toString();

  return(
    <div className={css(styles.container)}>
        <div className={css(styles.headerContainer)}>
            <span className={css(styles.headerIconContainer)} onClick={() => {
                history.replace('/list');
            }}>
                <BackIcon className={css(styles.headerIcon)} fill={SECONDARY_TEXT_COLOR}/>
            </span>
            <span className={css(styles.headerText)}>{title}</span>
            {showDeleteIcon ?
            (
                <span className={css(styles.headerIconContainer)} onClick={() => {
                    onClickRemoveAlarm();
                }}>
                    <DeleteIcon className={css(styles.headerIcon)} fill={SECONDARY_TEXT_COLOR}/>
                </span>
            ) : <span className={css(styles.headerIconContainer)}/>
            }
        </div>

        <div className={css(styles.contentContainer)}>
            <TextField
                className={materialStyles.textField}
                inputProps={{ style: { color: PRIMARY_TEXT_COLOR } }}
                value={name}
                onChange={(newName) => setName(newName.target.value)}
                id="name"
                label="Name (optional)"
                variant="standard" />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MultiSectionDigitalClock
                    className={materialStyles.timeSelect}
                    value={dayjs('2022-04-17T' + hoursForTimestamp + ':' + minutesForTimestamp)}
                    ampm={false}
                    defaultValue={dayjs('2022-04-17T06:00')}
                    timeSteps={{ hours: 1, minutes: 1 }}
                    onChange={(newTime) =>{
                        if (newTime) {
                            setHours((newTime as any).$H);
                            setMinutes((newTime as any).$m);
                        }
                    }}
                    />
            </LocalizationProvider>

            <div className={css(styles.spacing)}></div>
            <span
                onClick={() => {
                    setTimerOnlyOnce(true);
                    setTimerOnMonday(false);
                    setTimerOnTuesday(false);
                    setTimerOnWednesday(false);
                    setTimerOnThursday(false);
                    setTimerOnFriday(false);
                    setTimerOnSaturday(false);
                    setTimerOnSunday(false);
                }}
                className={css(styleForDaySelectionOnlyOnce)}
            >{captionChosenOnlyOnce}</span>
            <div className={css(styles.smallSpacing)}></div>
            <div className={css(styles.chooseSingleDaysContainer)}>
                <span
                    onClick={() => {
                        setTimerOnlyOnce(false);
                        setTimerOnMonday(!timerOnMonday);
                    }}
                    className={css(styleForDaySelectionMonday)}
                >Mo</span>
                <div className={css(styles.verticalspacing)}></div>
                <span
                    onClick={() => {
                        setTimerOnlyOnce(false);
                        setTimerOnTuesday(!timerOnTuesday);
                    }}
                    className={css(styleForDaySelectionTuesday)}
                >Di</span>
                <div className={css(styles.verticalspacing)}></div>
                <span
                    onClick={() => {
                        setTimerOnlyOnce(false);
                        setTimerOnWednesday(!timerOnWednesday);
                    }}
                    className={css(styleForDaySelectionWednesday)}
                >Mi</span>
                <div className={css(styles.verticalspacing)}></div>
                <span
                    onClick={() => {
                        setTimerOnlyOnce(false);
                        setTimerOnThursday(!timerOnThursday);
                    }}
                    className={css(styleForDaySelectionThursday)}
                >Do</span>
                <div className={css(styles.verticalspacing)}></div>
                <span
                    onClick={() => {
                        setTimerOnlyOnce(false);
                        setTimerOnFriday(!timerOnFriday);
                    }}
                    className={css(styleForDaySelectionFriday)}
                >Fr</span>
                <div className={css(styles.verticalspacing)}></div>
                <span
                    onClick={() => {
                        setTimerOnlyOnce(false);
                        setTimerOnSaturday(!timerOnSaturday);
                    }}
                    className={css(styleForDaySelectionSaturday)}
                >Sa</span>
                <div className={css(styles.verticalspacing)}></div>
                <span
                    onClick={() => {
                        setTimerOnlyOnce(false);
                        setTimerOnSunday(!timerOnSunday);
                    }}
                    className={css(styleForDaySelectionSunday)}
                >So</span>
            </div>
            <div className={css(styles.seperator)}></div>
            <div className={css(styles.switchContainer)}>
                <FormControlLabel className={materialStyles.switch} control={
                    <Switch
                        checked={lightEnabled}
                        onChange={() => setLightEnabled(!lightEnabled)} />
                }
                    label=""
                />
                <span className={css(lightEnabled ? styles.switchLabelActive : styles.switchLabelInactive)}>Licht</span>
            </div>
            <div className={css(styles.spacing)}></div>
            <div className={css(styles.smallSpacing)}></div>
            <Slider
                className={lightEnabled ? materialStyles.sliderActive : materialStyles.sliderInactive}
                aria-label="Light Intensity"
                value={lightIntensity}
                onChange={(_, value) => {
                    setLightIntensity(value as number)
                }}
                getAriaValueText={sliderLightTransform}
                step={1}
                disabled={!lightEnabled}
                marks={sliderLightMarks}
                valueLabelDisplay="on"
            />
            <div className={css(lightEnabled ? styles.sliderLabelActive : styles.sliderLabelInactive)}>Maximale Dimmung</div>
            <div className={css(styles.spacing)}></div>
            <div className={css(styles.spacing)}></div>
            <Slider
                className={lightEnabled ? materialStyles.sliderActive : materialStyles.sliderInactive}
                aria-label="Light duration"
                value={lightDuration}
                onChange={(_, value) => {
                    setLightDuration(value as number)
                }}
                getAriaValueText={sliderLightDurationTransform}
                step={30}
                min={30}
                max={600}
                disabled={!lightEnabled}
                marks={sliderLightDurationMarks}
                valueLabelDisplay="on"
            />
            <div className={css(lightEnabled ? styles.sliderLabelActive : styles.sliderLabelInactive)}>Dauer</div>

            <div className={css(styles.spacing)}></div>
            <div className={css(styles.spacing)}></div>
            <div className={css(styles.seperator)}></div>
            <div className={css(styles.switchContainer)}>
                <FormControlLabel className={materialStyles.switch} control={
                    <Switch
                        checked={blindsEnabled}
                        onChange={() => setBlindsEnabled(!blindsEnabled)} />
                }
                    label=""
                />
                <span className={css(blindsEnabled ? styles.switchLabelActive : styles.switchLabelInactive)}>Rollo</span>
            </div>
            <div className={css(styles.spacing)}></div>
            <div className={css(styles.smallSpacing)}></div>
            <Slider
                className={blindsEnabled ? materialStyles.sliderActive : materialStyles.sliderInactive}
                aria-label="Blinds openess"
                value={blindsOpeness}
                onChange={(_, value) => {
                    setBlindsOpeness(value as number)
                }}
                getAriaValueText={sliderBlindsTransform}
                step={1}
                disabled={!blindsEnabled}
                marks={sliderBlindsMarks}
                valueLabelDisplay="on"
            />
            <div className={css(blindsEnabled ? styles.sliderLabelActive : styles.sliderLabelInactive)}>Maximale Rolloöffnung</div>
            <div className={css(styles.spacing)}></div>
            <div className={css(styles.spacing)}></div>
            <Slider
                className={blindsEnabled ? materialStyles.sliderActive : materialStyles.sliderInactive}
                aria-label="Blinds duration"
                value={blindsDuration}
                onChange={(_, value) => {
                    setBlindsDuration(value as number)
                }}
                getAriaValueText={sliderBlindsDurationTransform}
                step={30}
                min={30}
                max={600}
                disabled={!blindsEnabled}
                marks={sliderBlindsDurationMarks}
                valueLabelDisplay="on"
            />
            <div className={css(blindsEnabled ? styles.sliderLabelActive : styles.sliderLabelInactive)}>Dauer</div>

            <div className={css(styles.spacing)}></div>
            <div className={css(styles.spacing)}></div>
            <div className={css(styles.spacing)}></div>
            <Button
                className={css(styles.primaryButton)}
                variant="contained"
                disabled={buttonAddAlarmDisabled}
                onClick={() => onClickSaveAlarm()}
                color="primary"
            >
                Speichern
            </Button>
        </div>
    </div>
  );
}

const materilUiStyles = makeStyles({
    textField: {
        width: 'calc(100% - 80px)',
        color: ACCENT_COLOR_DARK,
        // input label when focused
        "& label.Mui-focused": {
            color: ACCENT_COLOR_DARK
        },
        // focused color for input with variant='standard'
        "& .MuiInput-underline:after": {
            borderBottomColor: ACCENT_COLOR_DARK
        },
        // focused color for input with variant='filled'
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: ACCENT_COLOR_DARK
        },
        // focused color for input with variant='outlined'
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
            borderColor: ACCENT_COLOR_DARK
            }
        }
    },
    timeSelect: {
        marginTop: '20px !important',
        background: ACCENT_COLOR_DARK,
        justifyContent: 'center',
        padding: '15px',
        width: 'auto !important',
        "& .MuiButtonBase-root": {
            color: PRIMARY_TEXT_COLOR,
            "&.Mui-selected": {
                fontWeight: "bold",
                backgroundColor: BACKGROUND_COLOR
            },
            "&.Mui-selected:hover": {
                fontWeight: "bold",
                backgroundColor: BACKGROUND_COLOR
            }
        },
    },
    switch: {
        alignSelf: 'start !important',
        "& .MuiSwitch-track": {
            background: PRIMARY_TEXT_COLOR,
            opacity: 0.4,
        },
        "& .MuiSwitch-thumb": {
            color: PRIMARY_TEXT_COLOR,
        },
        "& .MuiSwitch-colorSecondary.Mui-checked": {
            color: ACCENT_COLOR_DARK
        },
        "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
            background: ACCENT_COLOR_DARK,
            opacity: 0.4,
        }
    },
    sliderInactive: {
        color: SECONDARY_TEXT_COLOR,
        opacity: 0.5,
        "& .MuiSlider-markLabel": {
            color: SECONDARY_TEXT_COLOR
        },
        "& .Mui-disabled": {
            color: SECONDARY_TEXT_COLOR
        },
        "& .MuiSlider-rail": {
            color: SECONDARY_TEXT_COLOR
        },
        "& .MuiSlider-track": {
            color: SECONDARY_TEXT_COLOR
        }
    },
    sliderActive: {
        color: PRIMARY_TEXT_COLOR,
        opacity: 1.0,
        "& .MuiSlider-markLabel": {
            color: PRIMARY_TEXT_COLOR
        }
    }
})

const styles = StyleSheet.create({
    container: {
      ...defaultStyles.containerStyle,
    },
    loadingContainer: {
        ...defaultStyles.containerStyle,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    },
    loadingSpinner: {
        color: ACCENT_COLOR_DARK
    },
    contentContainer: {
        ...defaultStyles.containerStyle,
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    headerContainer: {
        height: 60,
        paddingLeft: '16px',
        paddingRight: '16px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: ACCENT_COLOR_DARK
    },
    headerIconContainer: {
        height: 36,
        width: 36,
        textAlign: "center",
    },
    chooseSingleDaysContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginBottom: '30px'
    },
    headerIcon: {
      height: 36,
      width: 36,
    },
    headerText: {
        ...defaultStyles.textStyle,
        fontSize: TEXT_SIZE_BIG,
        fontWeight: 'bold',
        color: SECONDARY_TEXT_COLOR,
        wordBreak: 'break-word',
    },
    spacing: {
      height: 20,
    },
    smallSpacing: {
        height: 12,
    },
    verticalspacing: {
        width: 10,
    },
    seperator: {
        height: 1,
        width: '100%',
        background: SECONDARY_TEXT_COLOR,
        opacity: 0.3,
        marginBottom: '30px'
    },
    daySelectionTextActive: {
        fontSize: TEXT_SIZE_SLIGHTLY_BIGGER,
        fontWeight: 'bold',
        color: PRIMARY_TEXT_COLOR,
    },
    daySelectionTextInactive: {
        fontSize: TEXT_SIZE_NORMAL,
        color: SECONDARY_TEXT_COLOR,
        opacity: 0.5
    },
    switchContainer: {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'flex-start',
    },
    switchLabelActive: {
        fontSize: TEXT_SIZE_BIG,
        color: PRIMARY_TEXT_COLOR,
        opacity: 1.0,
        marginLeft: -12,
    },
    switchLabelInactive: {
        fontSize: TEXT_SIZE_BIG,
        color: SECONDARY_TEXT_COLOR,
        opacity: 0.5,
        marginLeft: -12,
    },
    sliderLabelActive: {
        marginTop: "-23px",
        color: PRIMARY_TEXT_COLOR,
        opacity: 1.0
    },
    sliderLabelInactive: {
        marginTop: "-23px",
        color: SECONDARY_TEXT_COLOR,
        opacity: 0.5
    },
    primaryButton: {
        ...defaultStyles.materialPrimaryButtonStyle,
        fontWeight: 'bold',
        color: PRIMARY_TEXT_COLOR,
        background: ACCENT_COLOR_DARK
      },
  });
  