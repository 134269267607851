import { Buffer } from 'buffer';

const USERNAME_STORE_NAME = 'stored_natural_alarm_username';
const PASSWORD_STORE_NAME = 'stored_natural_alarm_password';

// @ts-ignore
window.Buffer = Buffer;

export async function checkAuthenticated(
  callback: (authenticated: boolean) => void
) {
  const username = getStoredUsername();
  const password = getStoredPassword();
  if (username && password) authenticate(username, password, callback);
  else callback(false);
}

export async function authenticate(
  username: string,
  password: string,
  callback: (authenticated: boolean) => void
) {
  try {
    fetch('/api/naturalAlarm/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username,
        password
      })
    })
    .then(async (response) => {
      const jsonData = await response.json();
      if (jsonData.success) {
        storeAuthenticationData(username, password);
        callback(true);
      } else {
        callback(false);
      }
    })
    .catch(() => {
      callback(false);
    });
  }
  catch {
    callback(false);
  }
}

/**
 * Construct the authorization header needed for authenticated requests.
 */
export function getAuthorizationHeader(): string {
  const username = getStoredUsername();
  const password = getStoredPassword();
  if (username && password) {
    return `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}`
  }

  return ''
}

/**
 * Execute a REST call to the backend. Includes authorization information if user is authorized.
 * @param path the path after the backend base URL (e.g. secure/news)
 * @param fetchOptions options that should be passed to fetch(…)
 * @param isPostRequest specify, if the result should be make via POST or GET
 * @param parseResultAsJson specify, if the result should be parsed and returned as json
 */
export async function fetchBackend(
  path: string,
  fetchOptions?: RequestInit,
  isPostRequest: boolean = false,
  parseResultAsJson: boolean = true
) {
  const defaultHeaders: { [key: string]: string } = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': "DE",
    'Authorization': getAuthorizationHeader(),
    'Access-Control-Allow-Credentials': 'true',
  };

  const defaultMethod = isPostRequest ? 'POST' : 'GET';
  const res = await fetch(`/api/${path}`, {
    method: defaultMethod,
    ...fetchOptions,
    headers: {
      ...defaultHeaders,
      ...(fetchOptions ? fetchOptions.headers : {}),
    },
  });

  if (parseResultAsJson) {
    const jsonData = await res.json()
    return jsonData;
  }

  return res;
}

export function getStoredUsername(): string | undefined {
  const username = localStorage.getItem(USERNAME_STORE_NAME);
  if (username) return username;

  return undefined;
}

function getStoredPassword(): string | undefined {
  const password = localStorage.getItem(PASSWORD_STORE_NAME);
  if (password) return password;

  return undefined;
}

function storeAuthenticationData(username: string, password: string) {
  localStorage.setItem(USERNAME_STORE_NAME, username);
  localStorage.setItem(PASSWORD_STORE_NAME, password);
}

export function clearAuthenticationData() {
  localStorage.removeItem(USERNAME_STORE_NAME);
  localStorage.removeItem(PASSWORD_STORE_NAME);
}
