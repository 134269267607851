import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import { checkAuthenticated } from './authentication';
import Login from './components/Login';
import ListAlarmsComponent from './components/ListAlarmsComponent';
import AddAlarmComponent from './components/AddAlarmComponent';

export const STORED_SELECTED_ROOM = 'stored_selected_room';

function App() {
  const [isAuthenticated, setIsAuthenticated] =
    useState<boolean | undefined>(undefined);

// Check Authentication status
useEffect(() => {
  async function checkAuthStatus() {
    await checkAuthenticated((authenticated: boolean) => {
      setIsAuthenticated(authenticated);
    });
  };
  if (isAuthenticated === undefined) checkAuthStatus();
});

if (isAuthenticated === undefined) {
  return null;
}

if(!isAuthenticated) {
  return <Login setAuthenticatedStatus={(authenticatedStatus: boolean) => {
    setIsAuthenticated(authenticatedStatus);
  }} />
}

return (
  <BrowserRouter forceRefresh={true}>
    <Switch>
      <Route exact path="/list">
        <ListAlarmsComponent/>
      </Route>
      <Route exact path="/add">
        <AddAlarmComponent/>
      </Route>
      <Route path="/edit/:id">
        <AddAlarmComponent/>
      </Route>
      <Route exact path="/">
        <Redirect to="/list" />
      </Route>
    </Switch>
  </BrowserRouter>
);
}

export default App;
