import { createTheme } from '@material-ui/core/styles';
import {
  ACCENT_COLOR,
  PRIMARY_BUTTON_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
  BACKGROUND_COLOR,
  SECONDARY_TEXT_COLOR,
} from './colors';
import {
  TEXT_SIZE_NORMAL,
  TEXT_SIZE_BUTTON_NORMAL,
} from './dimens';

export const MAX_WIDTH_CONTENT = 1080;
export const DEFAULT_PADDING = 30;

export const defaultStyles = {
  containerStyle: {
    background: BACKGROUND_COLOR,
    margin: '0 auto',
  },
  textStyle: {
    color: PRIMARY_TEXT_COLOR,
    fontSize: TEXT_SIZE_NORMAL,
    lineHeight: '1.25em',
    fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
  },
  materialPrimaryButtonStyle: {
    fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
    fontSize: TEXT_SIZE_BUTTON_NORMAL,
    fontWeight: 'bold',
    color: PRIMARY_BUTTON_TEXT_COLOR,
    minWidth: 120,
  },
};

export const materialUiTheme = createTheme({
  palette: {
    primary: {
      main: ACCENT_COLOR,
    },
    secondary: {
      main: SECONDARY_TEXT_COLOR,
    },
  },
});
