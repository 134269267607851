import { css, StyleSheet } from 'aphrodite';
import React, { useEffect, useState } from 'react';
import { clearAuthenticationData, fetchBackend, getStoredUsername } from '../authentication';
import { ACCENT_COLOR, ACCENT_COLOR_DARK, PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from '../styling/colors';
import { defaultStyles, materialUiTheme } from '../styling/styles';
import { ReactComponent as BedIcon } from '../icons/bed.svg';
import { ReactComponent as ChildIcon } from '../icons/child.svg';
import { ReactComponent as BlindsIcon } from '../icons/blinds.svg';
import { ReactComponent as BlindsDisabledIcon } from '../icons/blinds-off.svg';
import { ReactComponent as LightIcon } from '../icons/light.svg';
import { ReactComponent as LightDisabledIcon } from '../icons/light-off.svg';
import { RoomsWithAlarm, Room, RoomWithAlarm } from '../server/model/RoomsWithAlarm';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { FormControlLabel, makeStyles, MuiThemeProvider, Switch } from '@material-ui/core';
import { STORED_SELECTED_ROOM } from '../App';
import { useHistory } from 'react-router-dom';
import { TEXT_SIZE_BIGGER, TEXT_SIZE_NORMAL } from '../styling/dimens';
import Luis from '../icons/Luis.png'

export default function ListAlarmsComponent() {
  const [roomsWithAlarm, setRoomsWithAlarm] = useState<RoomsWithAlarm | undefined>(
    undefined
  );
  const [selectedRoom, setSelectedRoom] = useState<Room | undefined>(
    undefined
  );

  const history = useHistory();
  const materialStyles = materilUiStyles();

  useEffect(() => {
    async function getRoomsWithAlarm() {
      const roomsWithAlarmsResponse = await fetchBackend('naturalAlarm/getAlarms');
      setRoomsWithAlarm(roomsWithAlarmsResponse);

      const storedSelectedRoom = localStorage.getItem(STORED_SELECTED_ROOM);
      if (storedSelectedRoom) {
        const foundRoomWithAlarmForStoredSelectedRoom = roomsWithAlarmsResponse.alarmsPerRoom.find((roomWithAlarm: RoomWithAlarm) => {
            return roomWithAlarm.alarmRoom === storedSelectedRoom;
        })
        if (foundRoomWithAlarmForStoredSelectedRoom) {
            setSelectedRoom(foundRoomWithAlarmForStoredSelectedRoom.alarmRoom)
        }
        else {
            setSelectedRoom(roomsWithAlarmsResponse.alarmsPerRoom[0].alarmRoom)
        }
      }
      else {
        setSelectedRoom(roomsWithAlarmsResponse.alarmsPerRoom[0].alarmRoom)
        localStorage.setItem(STORED_SELECTED_ROOM, roomsWithAlarmsResponse.alarmsPerRoom[0].alarmRoom);
      }
    };
    if (roomsWithAlarm === undefined) getRoomsWithAlarm();
  }, [roomsWithAlarm]);

  function changeSelectedRoom(newRoom: Room) {
    localStorage.setItem(STORED_SELECTED_ROOM, newRoom);
    setSelectedRoom(newRoom);
  }

  async function onClickChangeEnabled(alarmId: number, newEnabledState: boolean) {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ 
        id: alarmId,
        enabled: newEnabledState
     }),
    };
    await fetchBackend(
      'naturalAlarm/changeEnabled',
      requestOptions
    );

    const roomsWithAlarmsResponse = await fetchBackend('naturalAlarm/getAlarms');
    setRoomsWithAlarm(roomsWithAlarmsResponse);
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await clearAuthenticationData();
    window.location.reload();
  };

  if (!roomsWithAlarm || !selectedRoom) return null

  const alarmsForSelectedRoom = roomsWithAlarm.alarmsPerRoom.find(roomWithAlarms => roomWithAlarms.alarmRoom === selectedRoom)?.alarms ?? [];
  return(
    <div className={css(styles.container)}>
        <div className={css(styles.headerContainer)}>
            {roomsWithAlarm.alarmsPerRoom.map((roomWithAlarm, _) => {
                const opacity = roomWithAlarm.alarmRoom === selectedRoom ? 1 : 0.2;
                var icon;
                switch(roomWithAlarm.alarmRoom) {
                    case "CHILD_ONE":
                        icon = <ChildIcon fill={SECONDARY_TEXT_COLOR} opacity={opacity}/>;
                        break;
                    case "PARENTS":
                        icon = <BedIcon fill={SECONDARY_TEXT_COLOR} opacity={opacity}/>;
                        break;
                    case "CHILD_TWO":
                        icon = <img src={Luis} className={css(roomWithAlarm.alarmRoom === selectedRoom ? styles.headerImage : styles.headerImageInactive)} alt="ChildTwo" />;
                        break;
                }

                return (
                    <span key={roomWithAlarm.alarmRoom + "header"} className={css(styles.headerIconContainer)} onClick={() => changeSelectedRoom(roomWithAlarm.alarmRoom)}>
                        <span className={css(styles.headerIcon)}>{icon}</span>
                    </span>
                );
            })}

            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 1, mr: 1 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <Avatar sx={{ width: 32, height: 32 }}>{getStoredUsername()?.[0]}</Avatar>
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Ausloggen
                </MenuItem>
            </Menu>
        </div>

        <div className={css(styles.contentContainer)}>
          {alarmsForSelectedRoom.map((alarm, index) => {
            var addedDays = '';
            if (alarm.enabledDays.indexOf('MONDAY') >= 0) addedDays += "Mo, ";
            if (alarm.enabledDays.indexOf('TUESDAY') >= 0) addedDays += "Di, ";
            if (alarm.enabledDays.indexOf('WEDNESDAY') >= 0) addedDays += "Mi, ";
            if (alarm.enabledDays.indexOf('THURSDAY') >= 0) addedDays += "Do, ";
            if (alarm.enabledDays.indexOf('FRIDAY') >= 0) addedDays += "Fr, ";
            if (alarm.enabledDays.indexOf('SATURDAY') >= 0) addedDays += "Sa, ";
            if (alarm.enabledDays.indexOf('SUNDAY') >= 0) addedDays += "So, ";
            if (addedDays.length > 2) addedDays = addedDays.substring(0, addedDays.length - 2);

            const daysText =
              alarm.enabledDays.length === 5 &&
              alarm.enabledDays.find(day => day === 'MONDAY') &&
              alarm.enabledDays.find(day => day === 'TUESDAY') &&
              alarm.enabledDays.find(day => day === 'WEDNESDAY') &&
              alarm.enabledDays.find(day => day === 'THURSDAY') &&
              alarm.enabledDays.find(day => day === 'FRIDAY') ?
              'Wochentags' :
              alarm.enabledDays.length === 2 &&
              alarm.enabledDays.find(day => day === 'SATURDAY') &&
              alarm.enabledDays.find(day => day === 'SUNDAY') ?
              'Wochenende' : 
              addedDays

            const date = new Date();
            const currentTimeHours = date.getHours();
            const currentTimeMinutes = date.getMinutes();
            var singleUseTimerInfo = "Heute"
            if (currentTimeHours > alarm.hours || (currentTimeHours === alarm.hours && currentTimeMinutes >= alarm.minutes)) {
              singleUseTimerInfo = "Morgen";
            }

            const dayInfo = alarm.isSingleUsedTimer ? singleUseTimerInfo : daysText;
            const timeHourText = alarm.hours < 10 ? "0" + alarm.hours : alarm.hours;
            const timeMinuteText = alarm.minutes < 10 ? "0" + alarm.minutes : alarm.minutes;

            return (
              <div key={index + "alarm"} className={css(styles.alarmContainer)}>
                <div
                  onClick={(event) => {
                    if ((event.target as any).id === "enableSwitch") {
                      onClickChangeEnabled(alarm.id, !alarm.enabled);
                    }
                    else { 
                      history.push('/edit/' + alarm.id)
                    }
                  }}
                  className={css(styles.alarmInnerContainer)}>
                  <div className={css(styles.alarmLeftContainer)}>
                    <FormControlLabel className={materialStyles.switch} control={
                        <Switch
                            id="enableSwitch"
                            checked={alarm.enabled}
                            onChange={() => {
                              onClickChangeEnabled(alarm.id, !alarm.enabled);
                            }} />
                    }
                        label=""
                    />
                    <div className={css(styles.alarmLeftDetailsContainer)}>
                      <div className={css(alarm.enabled ? styles.time : styles.timeInactive)}>{timeHourText}:{timeMinuteText}</div>
                      {alarm.name ? <div className={css(alarm.enabled ? styles.additionalInfo : styles.additionalInfoInactive)}>{alarm.name}</div> : null}
                      <div className={css(alarm.enabled ? styles.additionalInfo : styles.additionalInfoInactive)}>{dayInfo}</div>
                    </div>
                  </div>
                  <div key={index + "alarm"} className={css(styles.alarmIconContainer)}>
                    {alarm.enabledLightDimmer
                      ? <LightIcon className={css(styles.alarmRightIcon, alarm.enabled ? styles.iconEnabled : styles.iconDisabled)} fill={alarm.enabled ? PRIMARY_TEXT_COLOR : SECONDARY_TEXT_COLOR}/>
                      : <LightDisabledIcon className={css(styles.alarmRightIcon, alarm.enabled ? styles.iconEnabled : styles.iconDisabled)} fill={alarm.enabled ? PRIMARY_TEXT_COLOR : SECONDARY_TEXT_COLOR}/>
                    }
                    {alarm.enabledWindowShade
                      ? <BlindsIcon className={css(styles.alarmRightIcon, alarm.enabled ? styles.iconEnabled : styles.iconDisabled)} fill={alarm.enabled ? PRIMARY_TEXT_COLOR : SECONDARY_TEXT_COLOR}/>
                      : <BlindsDisabledIcon className={css(styles.alarmRightIcon, alarm.enabled ? styles.iconEnabled : styles.iconDisabled)} fill={alarm.enabled ? PRIMARY_TEXT_COLOR : SECONDARY_TEXT_COLOR}/>
                    }
                  </div>
                </div>
                {index === alarmsForSelectedRoom.length -1 ? null: (<div className={css(styles.divider)}/>)}
              </div>
            )
          })}
        </div>

        <MuiThemeProvider theme={materialUiTheme}>
            <div className={css(styles.fabContainer)}>
                <Fab className={css(styles.fab)} onClick={() => history.push('/add')} color='primary' aria-label="add">
                    <AddIcon />
                </Fab>
            </div>
        </MuiThemeProvider>
    </div>
  );
}

const materilUiStyles = makeStyles({
  switch: {
      "& .MuiSwitch-track": {
          background: PRIMARY_TEXT_COLOR,
          opacity: 0.4,
      },
      "& .MuiSwitch-thumb": {
        color: PRIMARY_TEXT_COLOR,
      },
      "& .MuiSwitch-colorSecondary.Mui-checked": {
          color: ACCENT_COLOR_DARK
      },
      "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
          background: ACCENT_COLOR_DARK,
          opacity: 0.4,
      }
  }
})

const styles = StyleSheet.create({
    container: {
      ...defaultStyles.containerStyle,
    },
    headerContainer: {
        height: 60,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        background: ACCENT_COLOR_DARK
    },
    headerIconContainer: {
        height: 48,
        padding: 6,
        flex: "1 1 auto",
        textAlign: "center",
        borderRight: "solid 1px " + SECONDARY_TEXT_COLOR + "33",
    },
    headerIcon: {
      height: 48,
      width: 48,
    },
    headerImage: {
      height: 48,
      width: 48,
      opacity: 1.0
    },
    headerImageInactive: {
      height: 48,
      width: 48,
      opacity: 0.2
    },
    alarmRightIcon: {
      height: 36,
      width: 36,
      marginLeft: 10
    },
    iconEnabled: {
      opacity: 1.0
    },
    iconDisabled: {
      opacity: 0.4
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around'
    },
    alarmContainer: {
      width: '-webkit-fill-available',
    },
    alarmInnerContainer: {
      padding: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    alarmLeftContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    alarmLeftDetailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '-10px'
    },
    alarmIconContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    spacing: {
      height: 20,
    },
    fabContainer: {
        position: "fixed",
        bottom: "20px",
        right: "20px",
    },
    fab: {
        background: ACCENT_COLOR,
    },
    divider: {
      height: 1,
      background: SECONDARY_TEXT_COLOR,
      marginLeft: 20,
      marginRight: 20,
      opacity: 0.3
    },
    time: {
      ...defaultStyles.textStyle,
      fontSize: TEXT_SIZE_BIGGER,
      color: PRIMARY_TEXT_COLOR,
      wordBreak: 'break-word',
      marginBottom: 2
    },
    timeInactive: {
      ...defaultStyles.textStyle,
      fontSize: TEXT_SIZE_BIGGER,
      color: SECONDARY_TEXT_COLOR,
      opacity: 0.4,
      wordBreak: 'break-word',
      marginBottom: 2
    },
    additionalInfo: {
      ...defaultStyles.textStyle,
      fontSize: TEXT_SIZE_NORMAL,
      color: PRIMARY_TEXT_COLOR,
      wordBreak: 'break-word'
    },
    additionalInfoInactive: {
      ...defaultStyles.textStyle,
      fontSize: TEXT_SIZE_NORMAL,
      color: SECONDARY_TEXT_COLOR,
      opacity: 0.4,
      wordBreak: 'break-word'
    },
  });
  